import { getStripePayments } from "@stripe/firestore-stripe-payments";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
//const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

const clientCredentials = {
	apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
	authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
	storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

function initFirebase() {
	if (typeof window !== undefined) {
		initializeApp(clientCredentials);
	}
}

const app = initializeApp(clientCredentials);

//const appCheck = initializeAppCheck(app, {
//    provider: new ReCaptchaV3Provider(process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY),
//    isTokenAutoRefreshEnabled: true,
//});

const auth = getAuth(app);

const realDB = getDatabase(app);

const db = getFirestore(app);

const storage = getStorage(app);

const functions = getFunctions(app);

const payments = getStripePayments(app, {
	productsCollection: "products",
	customersCollection: "customers",
});

export { initFirebase, app, auth, db, payments, realDB, storage, functions };
