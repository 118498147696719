import { LoadingOverlay } from '@mantine/core';
import { useRouter } from 'next/router';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, initFirebase } from "../lib/firebase/initFirebase";

initFirebase()

export default function Home() {
    const [user, loading] = useAuthState(auth)
    const router = useRouter()

    if (loading) {
        return (
            <div style={{
                // fill screen
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                // center content
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <LoadingOverlay
                    loaderProps={{ size: 'xl', color: 'blue' }}
                    overlayOpacity={0}
                    overlayColor="#c5c5c5"
                    visible
                />
            </div>
        )
    }

    if (user) { // user is signed in
        router.push("/dashboard")
    } else {
        router.push("/signin")
    }
}
